import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Footer } from '../../../theme/AgreementStyle';
import { PauseAgreementConfirmation } from '../ContractModals';
import { PageLoader, Button } from '../../../common';
import {
  ContractFooterDefaultProptypes,
  ContractFooterProptypes,
} from '../PropTypesConstants/ContractFooterProptypes';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
} from '../../../constants';
import {
  updateAccountDetails,
  updatePauseAgreement,
  getPauseAgreementDetails,
  saveDraftAddendum,
  getAgreementData,
  updateBillingOffBoardingApproveReject,
} from '../../../api';

export default function ContractFooter({
  details,
  setParams,
  setShowModal,
  isEditContract,
  onEditcontract,
  isLoading,
  isFooter,
  newAddendumData,
  updatedFormData,
  showEditor,
  nextStep,
  setShowDiscardModal,
  checkApprovalCondition,
  showRightTick,
  setIsEditContract,
  renderEditContractBtn,
  showDiscardModal,
  createAgreementDoc,
  setIsLoading,
  getContractDetails,
  contractAddendumDetails,
  getContractActivityLogInfo,
  handleAddendumModal,
  formData,
}) {
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isSpreetailUser = userInfo?.role === SpreetailRole;

  const { pauseId } = useParams();
  const [showPauseModal, setShowPauseModal] = useState({
    show: false,
    data: {},
  });
  const [pauseAgreementData, setPauseAgreementData] = useState({});
  const [agreementGetData, setAgreementGetData] = useState({});
  const contractStatus = details?.contract_status?.value;

  const finalRequestApprovalUser = userInfo?.is_final_request_approvers;
  const firstRequestApprovalUser = userInfo?.is_primary_request_approvers;

  useEffect(() => {
    if (finalRequestApprovalUser || firstRequestApprovalUser) {
      getAgreementData(details?.id, 'cancel', !!finalRequestApprovalUser).then(
        (res) => {
          setAgreementGetData(res?.data);
        },
      );
    }
  }, [details.id, finalRequestApprovalUser, firstRequestApprovalUser]);

  const BGSManagerOrAdmin =
    userInfo?.role?.includes('BGS Manager') ||
    userInfo?.role?.includes('BGS Admin') ||
    finalRequestApprovalUser ||
    firstRequestApprovalUser ||
    userInfo?.role?.includes('Sales Manager') ||
    userInfo?.role?.includes('Sales');

  const rightTickCondition =
    showRightTick('service_agreement') &&
    showRightTick('feeStructure') &&
    showRightTick('statement') &&
    showRightTick('dspAddendum');

  const saveContractData = (data) => {
    const contractOrAddendum = contractAddendumDetails?.notActive
      ? saveDraftAddendum(contractAddendumDetails?.id, data)
      : updateAccountDetails(details.id, data);
    contractOrAddendum.then((res) => {
      if (res && res.status === 200) {
        if (data.contract_status === 'cancel') {
          toast.success('Cancel Contract Approved!');
        }

        getContractDetails();
        setShowPauseModal({ show: false, data: {} });
        setIsLoading({ loader: false, type: 'button' });
      } else {
        if (data.contract_status === 'cancel') {
          toast.error(res?.data?.detail);
        }
        setShowPauseModal({ show: false, data: {} });
        setIsLoading({ loader: false, type: 'button' });
      }
    });
  };

  const updateContractData = (data, cancelStatus) => {
    setIsLoading({ loader: true, type: 'button' });
    setIsLoading({ loader: true, type: cancelStatus });

    if (cancelStatus) {
      updateBillingOffBoardingApproveReject(
        cancelStatus,
        agreementGetData?.suspend_contract_id,
      ).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          getContractActivityLogInfo();
          setIsLoading({ loader: false, type: cancelStatus });
        } else {
          setIsLoading({ loader: false, type: cancelStatus });
        }
      });
    } else {
      saveContractData(data);
    }
  };

  const updatePauseContract = (pauseAgreementPauseData) => {
    const TodaysDate = dayjs(new Date()).format('YYYY-MM-DD');

    updatePauseAgreement(pauseId, pauseAgreementPauseData).then((pauseRes) => {
      if (pauseRes && pauseRes.status === 200) {
        toast.success('Pause Contract Approved!');
        let contractStatusData = {};
        if (pauseRes?.data?.start_date === TodaysDate) {
          contractStatusData = {
            contract_status: 'pause',
          };
        } else if (details && details.is_renewed) {
          contractStatusData = {
            contract_status: 'renewed',
          };
        } else {
          contractStatusData = {
            contract_status: 'active',
          };
        }
        updateContractData(contractStatusData);
      } else {
        toast.error(pauseRes?.data?.detail);
        setShowPauseModal({ show: false, data: {} });
        setIsLoading({ loader: false, type: 'button' });
      }
    });
  };

  const getDataToUpdatePauseAgreement = (
    pauseAgreement = pauseAgreementData,
  ) => {
    const TodaysDate = dayjs(new Date()).format('YYYY-MM-DD');
    let pauseAgreementPauseData = {};
    if (
      pauseAgreement?.start_date &&
      pauseAgreement.start_date === TodaysDate
    ) {
      pauseAgreementPauseData = {
        is_approved: true,
        is_extended: true,
        is_paused: true,
      };
    } else {
      pauseAgreementPauseData = { is_approved: true };
    }
    return pauseAgreementPauseData;
  };

  const onClickOfUpdatePauseContract = (flag) => {
    setIsLoading({ loader: true, type: 'button' });
    if (flag.getPauseAgreement) {
      getPauseAgreementDetails(pauseId).then((res) => {
        setIsLoading({ loader: false, type: 'button' });
        if (res && res.status === 200) {
          setPauseAgreementData(res && res.data);
          if (res?.data?.end_date && new Date(res.data.end_date) < new Date()) {
            setShowPauseModal({ show: true, data: res.data });
          } else {
            setIsLoading({ loader: true, type: 'button' });
            updatePauseContract(getDataToUpdatePauseAgreement(res && res.data));
          }
        }
      });
    } else {
      setIsLoading({ loader: true, type: 'button' });
      updatePauseContract(getDataToUpdatePauseAgreement());
    }
  };

  const renderContractButtonHtml = (onClickFunc, btnLabel, loaderLabel) => {
    return (
      <>
        <span
          data-tip={
            isSpreetailUser
              ? SpreetailRoleRestrictMessage
              : 'Approved! Waiting for Cancellation.'
          }
          data-for="cancel-button"
        >
          <Button
            style={{
              width: btnLabel === 'Direct request to Leadership' ? '250px' : '',
            }}
            className={`btn-primary sticky-btn-primary sidepanel mt-3  ${
              isEditContract
                ? 'w-sm-100 ml-0 mr-0'
                : btnLabel === 'Direct request to Leadership' ||
                  btnLabel === 'Approve Cancellation'
                ? 'w-sm-50 ml-0 mr-3'
                : 'w-sm-50 ml-0'
            }`}
            onClick={() => onClickFunc()}
            disabled={
              isSpreetailUser ||
              (agreementGetData?.request_status === 'approved' &&
                contractStatus === 'pending for cancellation' &&
                contractStatus === 'pending cancellation approval' &&
                btnLabel === 'Approval for Cancellation' &&
                finalRequestApprovalUser)
            }
          >
            {isLoading.loader && isLoading.type === loaderLabel ? (
              <PageLoader color="#fff" type="button" />
            ) : (
              btnLabel
            )}
          </Button>
          {isSpreetailUser ||
          (agreementGetData?.request_status === 'approved' &&
            contractStatus === 'pending for cancellation' &&
            contractStatus === 'pending cancellation approval' &&
            btnLabel === 'Approval for Cancellation' &&
            finalRequestApprovalUser) ? (
            <ReactTooltip
              id="cancel-button"
              aria-haspopup="true"
              place="top"
              disabled
            />
          ) : (
            ''
          )}
        </span>
      </>
    );
  };

  const renderRequestButtonHtml = (
    btnLabel,
    btnClassName,
    checkIsEditContract,
  ) => {
    return (
      <>
        <Button
          className={`btn-primary on-boarding mt-3 ${btnClassName}${
            checkIsEditContract === 'true'
              ? isEditContract
                ? 'w-sm-100'
                : 'w-sm-50'
              : ''
          }`}
          disabled={isEditContract}
        >
          {btnLabel}
        </Button>
      </>
    );
  };

  const displayFooterForManagers = () => {
    return rightTickCondition ? (
      <>
        {!isEditContract ? (
          <>
            {!BGSManagerOrAdmin ? null : (
              <Button
                className={`btn-primary on-boarding  w-320 mt-3 ml-0 ${
                  isEditContract ? 'w-sm-100' : 'w-sm-50'
                }`}
                disabled={!rightTickCondition}
                onClick={() => {
                  createAgreementDoc();
                  setParams('select-contact');
                  setShowModal(true);
                  setIsEditContract(false);
                }}
              >
                Approve and Request Signature
              </Button>
            )}
          </>
        ) : (
          renderRequestButtonHtml(
            'Approve and Request Signature',
            'w-320 ml-0',
            'true',
          )
        )}
        {!isEditContract
          ? renderEditContractBtn('light-orange w-sm-50 ml-5')
          : null}
      </>
    ) : !isEditContract ? (
      <>{renderEditContractBtn('btn-primary')}</>
    ) : (
      renderRequestButtonHtml(
        'Approve and Request Signature',
        'w-320 ml-0',
        'true',
      )
    );
  };

  const displayApprovalBtn = () => {
    return !isEditContract ? (
      <Button
        className={`btn-primary on-boarding mt-3  ${
          isEditContract ? 'w-sm-100' : 'w-sm-50 ml-0'
        }`}
        disabled={
          contractAddendumDetails?.notActive
            ? false
            : !rightTickCondition ||
              Object.keys(updatedFormData).includes('addendum')
        }
        onClick={() => {
          createAgreementDoc();
          setParams('request-approve');
          setShowModal(true);
        }}
      >
        Request Approval
      </Button>
    ) : (
      renderRequestButtonHtml('Request Approval', 'mr-4 w-sm-100', 'false')
    );
  };
  const displayApprovalFooterForInternalUsers = (itsDspOrAmcWithApprover) => {
    return rightTickCondition ? (
      <>
        {BGSManagerOrAdmin || itsDspOrAmcWithApprover ? (
          <>
            {!contractAddendumDetails?.notActive ? (
              <span>{displayApprovalBtn()}</span>
            ) : (
              displayApprovalBtn()
            )}
          </>
        ) : (
          ''
        )}

        {!isEditContract
          ? renderEditContractBtn(
              BGSManagerOrAdmin || itsDspOrAmcWithApprover
                ? 'light-orange w-sm-50 ml-5'
                : 'light-orange w-sm-50',
            )
          : null}
      </>
    ) : !isEditContract ? (
      <>{renderEditContractBtn('btn-primary')}</>
    ) : (
      renderRequestButtonHtml('Request Approval', 'mr-4 w-sm-100', 'false')
    );
  };

  // PDV-9064- itsDspOrAmcWithApprover is true when contract is DSP only or AMC only
  // and the logged in user have dspAmcApproval flag as true.
  // if itsDspOrAmcWithApprover is true then, show the Request Approval Btn.

  const displayApprovalFooter = (itsDspOrAmcWithApprover = false) => {
    if (finalRequestApprovalUser) return displayFooterForManagers();
    return displayApprovalFooterForInternalUsers(itsDspOrAmcWithApprover);
  };

  const displayRequestSignatureFooter = () => {
    return rightTickCondition ? (
      <>
        {!isEditContract ? (
          <>
            {BGSManagerOrAdmin || userInfo?.role?.includes('BGS') ? (
              <Button
                className={`btn-primary on-boarding mt-3 ml-0 ${
                  isEditContract ? 'w-sm-100 ' : 'w-sm-50 '
                }`}
                onClick={() => {
                  createAgreementDoc();
                  setParams('select-contact');
                  setShowModal(true);
                  setIsEditContract(false);
                }}
              >
                Request Signature
              </Button>
            ) : null}
          </>
        ) : (
          renderRequestButtonHtml('Request Signature', 'mr-5 w-sm-100', 'false')
        )}
        {!isEditContract
          ? renderEditContractBtn(
              BGSManagerOrAdmin || userInfo?.role?.includes('BGS')
                ? 'light-orange w-sm-50 ml-5'
                : 'light-orange w-sm-50 ',
            )
          : null}
      </>
    ) : !isEditContract ? (
      <>{renderEditContractBtn('btn-primary w-sm-100')}</>
    ) : (
      renderRequestButtonHtml('Request Signature', 'mr-5 w-sm-100', 'false')
    );
  };

  const displayUnsavedChangesCount = () => {
    if (
      Object.keys(updatedFormData)?.length &&
      Object.keys(updatedFormData)?.includes('fee_structure')
    ) {
      if (
        Object.keys(updatedFormData?.fee_structure)?.includes('vendor') &&
        Object.keys(updatedFormData?.fee_structure)?.includes('seller')
      ) {
        return (
          Object.keys(updatedFormData)?.length +
          Object.keys(updatedFormData?.fee_structure?.vendor)?.length +
          Object.keys(updatedFormData?.fee_structure?.seller)?.length -
          1
        );
      }
      if (
        Object.keys(updatedFormData?.fee_structure)?.includes('vendor') &&
        updatedFormData?.fee_structure?.vendor !== undefined
      ) {
        return (
          Object.keys(updatedFormData)?.length +
          Object.keys(updatedFormData?.fee_structure?.vendor)?.length -
          1
        );
      }
      if (Object.keys(updatedFormData?.fee_structure)?.includes('seller')) {
        return (
          Object.keys(updatedFormData)?.length +
          Object.keys(updatedFormData?.fee_structure?.seller)?.length -
          1
        );
      }
    }
    return Object.keys(updatedFormData).length;
  };

  const displayFooterForSaveChanges = () => {
    return (
      <div className="mt-4 pt-5">
        <Footer className=" mt-5">
          <div className="container-fluid">
            <Button
              className="light-orange  on-boarding  mt-3  mr-0 ml-0 w-sm-50"
              onClick={() =>
                contractAddendumDetails?.notActive
                  ? handleAddendumModal(true)
                  : nextStep()
              }
            >
              {isLoading.loader && isLoading.type === 'button' ? (
                <PageLoader color="#fff" type="button" />
              ) : (
                <>Save Changes</>
              )}
            </Button>

            <Button
              className="btn-borderless contract-btn on-boarding  mt-3  w-sm-50 ml-5"
              onClick={() =>
                setShowDiscardModal({
                  ...showDiscardModal,
                  show: true,
                  clickedBtn: 'discard',
                  openAfterEffectivityDateModal: false,
                })
              }
            >
              Discard Changes
            </Button>
            {updatedFormData && Object.keys(updatedFormData).length ? (
              <span className="unsave-changes">
                {displayUnsavedChangesCount()} unsaved changes.
              </span>
            ) : (
              ''
            )}
          </div>
        </Footer>
      </div>
    );
  };
  return (
    <>
      {showPauseModal.show ? (
        <PauseAgreementConfirmation
          showPauseModal={showPauseModal}
          onClickOfUpdatePauseContract={onClickOfUpdatePauseContract}
          isLoading={isLoading}
          setShowPauseModal={setShowPauseModal}
        />
      ) : null}

      {agreementGetData?.request_status !== 'approved' &&
      (contractStatus === 'pending cancellation approval' ||
        contractStatus === 'pending for cancellation') ? (
        <>
          {finalRequestApprovalUser || firstRequestApprovalUser ? (
            <div className="mt-4 pt-5">
              <Footer className=" mt-5 ">
                <div className="container-fluid">
                  {firstRequestApprovalUser
                    ? renderContractButtonHtml(
                        () =>
                          updateContractData(
                            {
                              contract_status: 'pending cancellation approval',
                            },
                            'partially approved',
                          ),
                        'Direct request to Leadership',
                        'partially approved',
                      )
                    : null}
                  {userInfo?.email === 'mona.sereno@buyboxexperts.com' ? (
                    ''
                  ) : (
                    <>
                      {/* PDV-9091 need to show Approve cancellation btn only for final approvers 
                    removed previous condition- 
                     { details?.is_ending_early && firstRequestApprovalUser ? '': show btn }
                    */}
                      {finalRequestApprovalUser
                        ? renderContractButtonHtml(
                            () =>
                              updateContractData(
                                {
                                  contract_status: 'pending for cancellation',
                                },
                                'approved',
                              ),
                            'Approve Cancellation',
                            'approved',
                          )
                        : null}
                      {renderContractButtonHtml(
                        () =>
                          updateContractData(
                            {
                              contract_status: details?.is_renewed
                                ? 'renewed'
                                : 'active',
                            },
                            'rejected',
                          ),
                        'Reject Cancellation',
                        'rejected',
                      )}
                    </>
                  )}
                </div>
              </Footer>
            </div>
          ) : null}
        </>
      ) : contractStatus === 'active pending for pause request' ||
        contractStatus === 'active pending for pause' ? (
        // PDV-9278 hide footer for active pending for pause agreement status
        ''
      ) : (
        <>
          {contractStatus === 'pending contract signature' ||
          contractAddendumDetails?.status === 'pending addendum signature' ? (
            userInfo?.role !== 'Customer' &&
            (customerState?.account_type === 'standalone' ||
              (customerState?.account_type === 'child' &&
                customerState?.active_contract)) ? (
              <div className="mt-4 pt-5">
                <Footer className=" mt-5 ">
                  <div className="container-fluid ">
                    {renderContractButtonHtml(
                      () => onEditcontract(),
                      `${
                        contractAddendumDetails?.notActive
                          ? 'Edit Addendum'
                          : 'Edit Agreement'
                      }`,
                    )}
                    {contractStatus === 'pending contract signature' ||
                    contractAddendumDetails?.status ===
                      'pending addendum signature' ? (
                      <>
                        <Button
                          data-tip={SpreetailRoleRestrictMessage}
                          data-for={
                            isSpreetailUser
                              ? 'spreetailRetrictSendRemainder'
                              : ''
                          }
                          className="light-orange sticky-btn   mt-3 mr-0 ml-5  on-boarding w-sm-50"
                          onClick={() => {
                            setParams('send-remainder');
                            setShowModal(true);
                          }}
                          disabled={isSpreetailUser}
                        >
                          Send Reminder
                        </Button>
                        {isSpreetailUser ? (
                          <ReactTooltip
                            id="spreetailRetrictSendRemainder"
                            aria-haspopup="true"
                            place="top"
                            effect="solid"
                            html
                          />
                        ) : null}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Footer>
              </div>
            ) : (
              ''
            )
          ) : isFooter ||
            (newAddendumData?.id && showEditor && updatedFormData?.addendum) ? (
            displayFooterForSaveChanges()
          ) : (
            <>
              {agreementGetData?.request_status === 'approved' ? (
                ''
              ) : userInfo?.role !== 'Customer' && // PDV-8257 subbrand ticket
                (customerState?.account_type === 'standalone' || // this condition block is to show footer
                  (customerState?.account_type === 'child' && // only to standalone customers or child with active contract
                    customerState?.active_contract)) ? (
                <div className="mt-4 pt-5">
                  <Footer>
                    <div className="container-fluid">
                      {['dsp only', 'amc only'].includes(
                        formData?.contract_type,
                      ) && userInfo?.is_dsp_amc_primary_request_approvers
                        ? displayApprovalFooter(true)
                        : checkApprovalCondition()
                        ? displayApprovalFooter()
                        : displayRequestSignatureFooter()}
                    </div>
                  </Footer>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

ContractFooter.defaultProps = ContractFooterDefaultProptypes;
ContractFooter.propTypes = ContractFooterProptypes;
