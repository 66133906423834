import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import Select, { components } from 'react-select';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MarketplaceDropdown from './MarketplaceDropdown';
import Theme from '../../../../../theme/Theme';
import {
  SpreetailRole,
  SpreetailRoleRestrictMessage,
} from '../../../../../constants';
import {
  CustomDropDown,
  ViewData,
  WhiteCard,
  DropdownIndicator,
  Button,
  SingleSelectDropdown,
} from '../../../../../common';

const filterCustomStyles = {
  valueContainer: (css) => ({
    ...css,
    color: '#2E384D',
    overflow: 'hidden',
    'white-space': 'nowrap',
    'flex-wrap': 'nowrap',
    'text-overflow': 'ellipsis',
    'max-width': '130px',
    'font-size': '12px',
    'font-family': 'Noah-Medium',
  }),
};

function GoalsAndStrategyFilters({
  newGoalsDataLength,
  editedGoalsDataLength,
  accountTypeOptions,
  selectedAccountType,
  handleAccountTypeOnChange,
  marketplaceOptions,
  handleOnChangeOfMarketplaces,
  selectedMarketplaces,
  dropdownLabel,
  isMarketplaceDropdownOpen,
  setIsMarketplaceDropdownOpen,
  dateType,
  isCustomMonthApply,
  customStartDate,
  customEndDate,
  dateFilterOptions,
  handleDateFilterEvents,
  isEditGoals,
  updateGoals,
  setEditGoals,
  setMarketplaceforNewGoal,
}) {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const customerData = useSelector((state) => state?.customerState?.data);
  const isPPC = customerData?.having_contract_type?.is_having_ppc;
  const isDSP = customerData?.having_contract_type?.is_having_dsp;
  const isDspOnlyBP = isDSP && !isPPC;
  const { Option, SingleValue } = components;

  // render the selected custom date or month as a sub label in the time filter
  const renderCustomDateSubLabel = (props) => {
    if (dateType?.value === 'custom' && isCustomMonthApply) {
      return `${dayjs(customStartDate).format('MMM, YYYY')} - ${dayjs(
        customEndDate,
      ).format('MMM, YYYY')}`;
    }
    return props?.data?.sub;
  };

  const singleFilterOption = (props) => {
    return (
      <Option {...props}>
        <div className="pb-2" style={{ fontSize: '14px', color: '#2e384d' }}>
          {props.data.label}
        </div>
        <div className="pb-2" style={{ fontSize: '12px', color: '#556178' }}>
          {renderCustomDateSubLabel(props)}
        </div>
      </Option>
    );
  };

  const singleFilterValue = (props) => {
    return (
      <SingleValue {...props}>
        <span style={{ fontSize: '14px', color: '#2e384d' }}>
          {renderCustomDateSubLabel(props)}
        </span>
      </SingleValue>
    );
  };

  // render get select component for dropdown
  const getSelectComponents = () => {
    return {
      Option: singleFilterOption,
      SingleValue: singleFilterValue,
      DropdownIndicator,
    };
  };

  const dropdownSingleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
      <div style={{ fontSize: '10px' }}>{props?.data?.sub}</div>
    </SingleValue>
  );

  const dropdownFilterOption = (props) => (
    <Option {...props}>
      <div>
        <span style={{ fontSize: '14px' }}>{props?.data?.label}</span>
        <div style={{ fontSize: '10px' }}>{props?.data?.sub}</div>
      </div>
    </Option>
  );

  const getAccountTypeDropdDownComponents = () => {
    return {
      Option: dropdownFilterOption,
      SingleValue: dropdownSingleFilterOption,
      DropdownIndicator,
    };
  };

  const displayAccountTypeFilter = () => {
    return (
      <>
        <div
          id="sales-performance-type-filter"
          style={{ position: 'relative' }}
        >
          <SingleSelectDropdown
            filterId="BT-SalesPerformanceTypeFilter"
            className="single-select-dropdown"
            dropdownOptions={accountTypeOptions}
            selectedValue={selectedAccountType}
            onChangeHandler={handleAccountTypeOnChange}
            dropdownComponents={getAccountTypeDropdDownComponents}
            // isApiCall={isApiCall}
            isSearchable={false}
          />
        </div>
      </>
    );
  };

  const displayMarketplaceFilter = () => {
    return (
      <MarketplaceDropdown
        marketplaceOptions={marketplaceOptions}
        handleChange={handleOnChangeOfMarketplaces}
        selectedMarketplaces={selectedMarketplaces}
        dropdownLabel={dropdownLabel}
        isMarketplaceDropdownOpen={isMarketplaceDropdownOpen}
        setIsMarketplaceDropdownOpen={setIsMarketplaceDropdownOpen}
      />
    );
  };

  // renderGoaldateFilter
  const renderGoalDateFilter = () => {
    return (
      <Select
        classNamePrefix="react-select"
        className="active cursor"
        fontSize="14px"
        options={dateFilterOptions}
        components={getSelectComponents()}
        value={dateType}
        defaultValue={dateType}
        onChange={(event) => handleDateFilterEvents(event)}
        styles={filterCustomStyles}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: '#1A1A1A',
            primary: 'none',
            primary25: 'none',
            primary50: 'none',
          },
        })}
      />
    );
  };

  const displayEditandSaveBtn = () => {
    return isEditGoals ? (
      <Button
        style={{ width: 'fit-content' }}
        type="button"
        className={` ${
          newGoalsDataLength ? 'disabled' : ''
        } btn-primary  extraNormal-text `}
        onClick={() => {
          updateGoals();
        }}
      >
        <FontAwesomeIcon
          className="mr-2"
          icon="fa-regular fa-check"
          color="#fff"
          fontSize="12px"
        />
        Save
      </Button>
    ) : (
      <>
        <Button
          data-tip={SpreetailRoleRestrictMessage}
          data-for={isSpreetailUser ? 'spreetailRetrictEditGoal' : ''}
          style={{ width: 'fit-content' }}
          className="btn-primary extraNormal-text"
          type="button"
          onClick={() => {
            setEditGoals(true);
            setMarketplaceforNewGoal();
          }}
          disabled={isSpreetailUser}
        >
          <FontAwesomeIcon
            icon="fa-regular fa-pen"
            style={{ color: '#fff', fontSize: '12px' }}
            className="cursor  pl-1"
          />{' '}
          Edit
        </Button>
        <ReactTooltip
          id="spreetailRetrictEditGoal"
          aria-haspopup="true"
          place="top"
          effect="solid"
          html
        />
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <WhiteCard className="mb-3 mt-3">
      <ViewData>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="view-data-for mt-2  mb-md-0 mb-2 d-md-block d-none ">
              View data for:
            </div>{' '}
          </div>
          <div className="col-md-9 col-12">
            <GoalsNav
              className="text-right"
              show={
                !customerData?.goal_set?.show ||
                !selectedMarketplaces?.length ||
                !editedGoalsDataLength
              }
            >
              <ul className="inline-content">
                <li className="action-btn">
                  {!customerData?.goal_set?.show ||
                  !selectedMarketplaces?.length > 0 ||
                  !editedGoalsDataLength > 0
                    ? null
                    : displayEditandSaveBtn()}
                </li>

                <li className={isDspOnlyBP ? 'd-none' : ''}>
                  <CustomDropDown className="w-100">
                    {displayAccountTypeFilter()}
                  </CustomDropDown>
                </li>

                <li>
                  <CustomDropDown className="w-100">
                    {displayMarketplaceFilter()}
                  </CustomDropDown>
                </li>
                <li>
                  <CustomDropDown className="w-100 cursor">
                    {renderGoalDateFilter()}
                  </CustomDropDown>
                </li>
              </ul>
            </GoalsNav>
          </div>
        </div>
      </ViewData>
    </WhiteCard>
  );
}

export default GoalsAndStrategyFilters;

GoalsAndStrategyFilters.defaultProps = {
  isMarketplaceDropdownOpen: false,
  isCustomMonthApply: false,
  isEditGoals: false,
  newGoalsDataLength: false,

  isBBEInternalUser: '',
  accountType: '',
  dropdownLabel: '',
  customStartDate: '',
  customEndDate: '',

  editedGoalsDataLength: number,

  data: {},
  dateType: {},
  selectedAccountType: {},

  accountTypeOptions: [],
  dateFilterOptions: [],
  marketplaceOptions: [],
  selectedMarketplaces: [],

  updateGoals: () => {},
  setEditGoals: () => {},
  setSelectedAccountType: () => {},
  setMarketplaceforNewGoal: () => {},
  handleDateFilterEvents: () => {},
  handleAccountTypeOnChange: () => {},
  setIsMarketplaceDropdownOpen: () => {},
  handleOnChangeOfMarketplaces: () => {},
};

GoalsAndStrategyFilters.propTypes = {
  isMarketplaceDropdownOpen: bool,
  isCustomMonthApply: bool,
  isEditGoals: bool,
  newGoalsDataLength: bool,

  dropdownLabel: string,
  customStartDate: string,
  customEndDate: string,
  accountType: string,
  isBBEInternalUser: string,

  editedGoalsDataLength: number,

  data: shape({
    label: string,
    sub: string,
  }),
  selectedAccountType: shape({}),
  dateType: shape({}),

  accountTypeOptions: arrayOf(shape({})),
  dateFilterOptions: arrayOf(shape({})),
  marketplaceOptions: arrayOf(shape({})),
  selectedMarketplaces: arrayOf(shape({})),

  updateGoals: func,
  setEditGoals: func,
  setSelectedAccountType: func,
  setMarketplaceforNewGoal: func,
  handleDateFilterEvents: func,
  handleAccountTypeOnChange: func,
  setIsMarketplaceDropdownOpen: func,
  handleOnChangeOfMarketplaces: func,
};

const GoalsNav = styled.div`
  .inline-content {
    margin-top: -6px;
    li {
      width: 200px;
      margin-right: 16px;
      color: ${Theme.black};
      font-size: ${Theme.extraNormal};
      vertical-align: top;
      &.action-btn {
        max-width: fit-content;
        width: fit-content;
        button {
          font-size: 14px;
          min-height: 40px;
          border-radius: 6px;
          padding: 5px 7px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
    .inline-content {
      li {
        width: ${(props) => (props.show ? '46%' : '37%')};
        margin-right: 8px;

        &.action-btn {
          max-width: 100%;
          width: auto;
        }
      }
    }
  }

  @media only screen and (max-width: 370px) {
    .inline-content {
      li {
        width: ${(props) => (props.show ? '45%' : '34%')};

        .dropdown-goal-filter {
          min-width: auto;
        }
      }
    }
  }
`;
