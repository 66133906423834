import React from 'react';

import { components } from 'react-select';
import { shape, string } from 'prop-types';

const DropdownSingleValue = ({ ...props }) => {
  const { SingleValue } = components;
  const { data } = props;

  /* ********** Main return statement of this component ********** */
  return (
    <SingleValue {...props}>
      <div className="dropdown-single-value">
        <span style={{ fontSize: '14px' }}>{data?.label}</span>
        <div style={{ fontSize: '10px' }}>{data?.sub}</div>
      </div>
    </SingleValue>
  );
};

export default DropdownSingleValue;
DropdownSingleValue.defaultProps = {
  data: {},
};

DropdownSingleValue.propTypes = {
  data: shape({ label: string, sub: string }),
};
