/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import { arrayOf, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../theme/Theme';
import NoRecordFound from '../../../../common/NoRecordFound';
import CustomerFeedbackDetails from './CustomerFeedbackDetails';
import { NotesSideBar } from '../CustomerDetailStyles';
import { getCustomerFeedback, getMonkeySurveyData } from '../../../../api';
import {
  DesktopTable,
  PageLoader,
  Status,
  ViewData,
  WhiteCard,
  DropdownIndicator,
  SingleSelectDropdown,
} from '../../../../common';

export default function CustomerFeedbackList({
  id,
  childBPList,
  customerType,
}) {
  const customStyles = {
    content: {
      top: '50%',
      right: '0px',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      maxHeight: '100%',
      overlay: ' {zIndex: 1000}',
      inset: '0% 0% 0% auto',
      marginRight: '0',
      borderRadius: '0px !important',
    },
  };

  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [showDetailedFeedback, setShowDetailedFeedback] = useState(false);
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState({});
  const [surveyData, setSurveyData] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );

  useEffect(() => {
    if (childBPList?.length) setSelectedCustomerId(childBPList?.[0]?.value);
  }, [childBPList]);

  useEffect(() => {
    setIsLoading({ loader: true, type: 'page' });
    getCustomerFeedback(selectedCustomerId).then((res) => {
      if (res?.status === 200) {
        getMonkeySurveyData(selectedCustomerId).then((survey) => {
          setSurveyData((prevState) => [...prevState, ...res.data]);

          for (const item of survey?.data?.results) {
            if (item?.survey_responses?.length) {
              for (const qa of item.survey_responses) {
                qa.id = item.id;
                qa.sent_on = item.sent_on;
                qa.status = item.status;
                qa.survey_name = item.survey_name;
              }
              setSurveyData((prevState) => [
                ...prevState,
                ...item.survey_responses,
              ]);
            } else {
              setSurveyData((prevState) => [...prevState, item]);
            }
          }
          setIsLoading({ loader: false, type: 'page' });
        });
      }
    });
  }, [selectedCustomerId]);

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        // isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  return (
    <>
      <div className="col-12 mb-3">
        {childBPList?.length && customerType === 'parent' ? (
          <WhiteCard className="mb-3">
            <ViewData>
              <div className="row">
                <div className="col-md-4  col-sm-12 ">
                  <div className="view-data-for mt-2 pt-1 ">View data for:</div>{' '}
                </div>
                <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
                <div className="col-md-4 col-12 pl-0">
                  {displayChildBPFilter()}
                </div>
              </div>
            </ViewData>
          </WhiteCard>
        ) : null}
        <WhiteCard className="activity-card">
          <>
            <p className="black-heading-title mt-0 mb-4">Partner Feedback</p>
            {isLoading.loader && isLoading.type === 'page' ? (
              <PageLoader
                component="page"
                color="#FF5933"
                type="page"
                width={40}
                height={40}
              />
            ) : (
              <>
                {surveyData?.length !== 0 ? (
                  <div className="scrollable-height">
                    <DesktopTable className="mt-0">
                      <thead>
                        <tr>
                          <th>Survey name</th>
                          <th>Status</th>
                          <th>Sent on</th>
                          <th>Completed on</th>
                        </tr>
                      </thead>

                      <tbody>
                        {surveyData?.map((item) => (
                          <tr
                            key={item?.id}
                            className={
                              item?.status === 'sent'
                                ? 'text-medium-font'
                                : 'text-medium-font cursor'
                            }
                            onClick={() => {
                              if (item?.status !== 'sent') {
                                setShowDetailedFeedback(true);
                                setSelectedFeedbackForm(item);
                              }
                            }}
                          >
                            <>
                              <td>{item.survey_name}</td>
                              <td>
                                <Status
                                  label={item.status}
                                  backgroundColor={
                                    item.status === 'answered'
                                      ? Theme.lightGreen
                                      : Theme.lightYellow
                                  }
                                  pointColor={
                                    item.status === 'answered'
                                      ? Theme.green
                                      : Theme.yellow
                                  }
                                />
                              </td>
                              <td>{item.sent_on || 'N/A'}</td>
                              <td>{item.completed_on || 'N/A'}</td>
                            </>
                          </tr>
                        ))}
                      </tbody>
                    </DesktopTable>
                  </div>
                ) : (
                  <NoRecordFound
                    type="feedback"
                    title="We looked high and low, but…"
                    subTitle="No records found."
                  />
                )}
              </>
            )}
          </>
        </WhiteCard>
      </div>

      {/* customer feedback detailed view modal */}
      <Modal
        isOpen={showDetailedFeedback}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <NotesSideBar>
          <div className="row m-0">
            <div className="col-9 uppercase-text py-3 ">
              {selectedFeedbackForm?.survey_name}
            </div>
            <div className="col-3">
              <FontAwesomeIcon
                icon="fa-light fa-times"
                style={{ color: '#000', fontSize: '20px' }}
                onClick={() => setShowDetailedFeedback(false)}
                className="float-right cursor py-3"
              />
              <div className="clear-fix" />
            </div>
            <div className="ml-3 mb-3" style={{ color: 'black' }}>
              {Object.keys(selectedFeedbackForm).includes('feedback') &&
              Object.keys(selectedFeedbackForm).includes('ratings')
                ? 'Survey is collected from BP during the Next account setup process to receive feedback on the process and identify areas for improvements.'
                : 'Survey is collected from BP after four weeks from activating their account.  Survey results are used to determine areas for improvement in the sales and onboarding processes. '}
            </div>
          </div>

          <CustomerFeedbackDetails
            selectedFeedbackForm={selectedFeedbackForm}
            surveyType={
              Object.keys(selectedFeedbackForm).includes('feedback') &&
              Object.keys(selectedFeedbackForm).includes('ratings')
                ? 'accountSetup'
                : 'onboarding'
            }
          />
        </NotesSideBar>
      </Modal>
    </>
  );
}

CustomerFeedbackList.defaultProps = {
  childBPList: [],
  customerType: 'standalone',
};

CustomerFeedbackList.propTypes = {
  id: string.isRequired,
  childBPList: arrayOf(shape({})),
  customerType: string,
};
