/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import React, { useEffect, useCallback } from 'react';

import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { Collapse } from 'react-collapse';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, func, shape, bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GoalMobileView from './GoalMobileView';
import Theme from '../../../../../theme/Theme';
import { InfoIcons } from '../../../../../theme/images';
import { TableWrapper, GoalsStyle, BorderLessField } from '../GoalsStyle';
import { DesktopTable, Button, ModalRadioCheck } from '../../../../../common';
import { goalsDataType } from '../../../../../constants';

function Goals({
  goalsType,
  isEditGoals,
  monthNames,
  selectedMarketplaces,
  editedGoalsData,
  setEditedGoalsData,
  updateGoals,
  newGoalsData,
  setNewGoalsData,
  deleteAddedGoal,
  isNewGoalAdded,
  setIsNewGoalAdded,
  marketplaceCollapse,
  setMarketplaceCollapse,
  marketplaceForNewGoal,
  setMarketplaceforNewGoal,
}) {
  const customerData = useSelector((state) => state.customerState.data);
  const isHavingPpc = customerData?.having_contract_type?.is_having_ppc;
  const isHavingDsp = customerData?.having_contract_type?.is_having_dsp;

  const monthLength = monthNames?.length;
  const isTablet = useMediaQuery({ minWidth: 768 });
  const cellTHWidth =
    monthLength === 1
      ? '50%'
      : monthLength === 2
      ? '33.33%'
      : monthLength === 3
      ? '25%'
      : monthLength === 4
      ? '20%'
      : '300px';

  const cellWidth =
    monthLength === 1
      ? '50%'
      : monthLength === 2
      ? '33.33%'
      : monthLength === 3
      ? '25%'
      : monthLength === 4
      ? '20%'
      : '300px';

  // handle new goals input change events
  const handleNewGoalInput = (
    event,
    monthData,
    monthIndex,
    selectedMarketplace,
    milestones,
    goal,
    item,
    newlyAddedGoalIndex,
  ) => {
    let value = '';
    if (item?.data_type === 'percentage') {
      value = event?.target?.value?.slice(0, -1);
    } else if (item?.data_type === 'dollar') {
      value = event?.target?.value?.slice(1);
    } else {
      value = event?.target?.value;
    }

    const editedValue = event?.target?.value
      ? value?.replace(/,/g, '')?.replace(/^0+/, '')
      : event?.target?.value;

    const list = [...item?.milestone];
    list[monthIndex] = { ...list[monthIndex], value: editedValue };

    const listOfNewlyAddedGoalOfMarketplce = newGoalsData?.filter((newGoal) =>
      Object.keys(newGoal)?.includes(selectedMarketplace?.id),
    );

    listOfNewlyAddedGoalOfMarketplce[0][selectedMarketplace?.id][
      newlyAddedGoalIndex
    ] = {
      ...listOfNewlyAddedGoalOfMarketplce[0][selectedMarketplace?.id][
        newlyAddedGoalIndex
      ],
      milestone: [...list],
    };

    // extract list of other marketplace and its newly added goal except selected marketpalce
    const goalsExceptUpdatedGoal = newGoalsData?.filter((newGoal) => {
      if (!Object.keys(newGoal)?.includes(selectedMarketplace?.id)) {
        return newGoal;
      }
    });

    // push updated object in list of goals which not contain selected marketplace data. so final list is created
    goalsExceptUpdatedGoal?.push(listOfNewlyAddedGoalOfMarketplce[0]);

    // update original list
    setNewGoalsData(goalsExceptUpdatedGoal);
  };

  // handle the goals input value change
  const handleInputChange = (
    event,
    monthData,
    index,
    selectedMarketplace,
    milestones,
  ) => {
    let value = '';
    if (milestones?.data_type === 'percentage') {
      value = event?.target?.value?.slice(0, -1);
    } else if (milestones?.data_type === 'dollar') {
      value = event?.target?.value?.slice(1);
    } else {
      value = event?.target?.value;
    }

    const editedValue = event?.target?.value
      ? milestones?.goal_name === 'DSP Ad Budget' ||
        milestones?.goal_name === 'PPC Ad Budget'
        ? value?.replace(/,/g, '')
        : value?.replace(/,/g, '')?.replace(/^0+/, '')
      : event?.target?.value;

    if (isEditGoals) {
      // takeout goal from editedlist which is changed
      const goalFromEditedGoalList = editedGoalsData?.filter(
        (goalsData) => goalsData?.marketplace === selectedMarketplace?.id,
      );

      let milestonesToBeUpdated = {};
      let milestonesIndex = null;

      if (goalFromEditedGoalList?.length) {
        // take milestone which we want to update
        milestonesToBeUpdated = goalFromEditedGoalList[0]?.milestones.find(
          (milestonesData) => milestonesData?.id === milestones?.id,
        );
        // this loop is for getting index of milestone which will be updating
        goalFromEditedGoalList[0]?.milestones?.forEach(
          (milestoneData, milestoneIndex) => {
            if (milestoneData?.id === milestones?.id) {
              milestonesIndex = milestoneIndex;
            }
            return null;
          },
        );
      }

      // below code to update milestones list
      const list = [...milestonesToBeUpdated?.milestone];

      list[index] = {
        ...milestonesToBeUpdated?.milestone[index],
        value: editedValue,
      };

      milestonesToBeUpdated = {
        ...milestonesToBeUpdated,
        milestone: [...list],
      };

      let parentMilestone = [];

      if (goalFromEditedGoalList?.length) {
        parentMilestone = [...goalFromEditedGoalList[0]?.milestones];
        parentMilestone[milestonesIndex] = milestonesToBeUpdated;
      }

      // calculation of Tacos goals
      if (
        milestones?.goal_name === 'Overall Sales Goals' ||
        milestones?.goal_name === 'DSP Ad Budget' ||
        milestones?.goal_name === 'PPC Ad Budget'
      ) {
        let tacosMilestonesToBeUpdated = {};
        let tacosMilestonesIndex = null;

        let overallSalesMilestoneList = [];
        let ppcAdMilestoneList = [];
        let dspAdMilestoneList = [];

        if (goalFromEditedGoalList?.length) {
          // take tacos milestone which we want to update
          tacosMilestonesToBeUpdated =
            goalFromEditedGoalList[0]?.milestones.find(
              (milestonesData) => milestonesData?.goal_name === 'TACOS',
            );

          // get milestones of overall sales goals
          overallSalesMilestoneList =
            goalFromEditedGoalList[0]?.milestones.find(
              (milestonesData) =>
                milestonesData?.goal_name === 'Overall Sales Goals',
            )?.milestone;

          // get milestones of ppc ad budget
          ppcAdMilestoneList = goalFromEditedGoalList[0]?.milestones.find(
            (milestonesData) => milestonesData?.goal_name === 'PPC Ad Budget',
          )?.milestone;

          // get milestones of dsp ad budget
          dspAdMilestoneList = goalFromEditedGoalList[0]?.milestones.find(
            (milestonesData) => milestonesData?.goal_name === 'DSP Ad Budget',
          )?.milestone;

          // this loop is for getting index of tacos milestone which will be updating
          goalFromEditedGoalList[0]?.milestones?.forEach(
            (milestoneData, milestoneIndex) => {
              if (milestoneData?.goal_name === 'TACOS') {
                tacosMilestonesIndex = milestoneIndex;
              }
              return null;
            },
          );
        }

        // PDV-7533 - calculation of tacos values
        // this should automatically be calculated as soon as [overall sales] goal and [total ad budget] goal is entered
        // Total Ad budget / overall sales  * 100 where total ad budget will be a sum of ppc + dsp budget
        // if BP only has PPC = PPC budget / overall sales * 100
        // if BP only has DSP = DSP budget / overall sales * 100
        // if BP has both PPC and DSP = Total Ad budget / overall sales * 100

        // get the overall sales values entered by user or previous value
        const overallSalesValue =
          milestones?.goal_name === 'Overall Sales Goals'
            ? Number(editedValue)
            : Number(overallSalesMilestoneList?.[index]?.value);
        // get the ppc ad budget values entered by user or previous value
        const ppcAdBudgetValue =
          milestones?.goal_name === 'PPC Ad Budget'
            ? Number(editedValue)
            : Number(ppcAdMilestoneList?.[index]?.value);
        // get the dsp ad budget values entered by user or previous value
        const dspAdBudgetValue =
          milestones?.goal_name === 'DSP Ad Budget'
            ? Number(editedValue)
            : Number(dspAdMilestoneList?.[index]?.value);

        // calculate total ad budget as per ppc-dsp flag
        const totalAdValue =
          isHavingPpc && isHavingDsp
            ? ppcAdBudgetValue + dspAdBudgetValue
            : isHavingPpc && !isHavingDsp
            ? ppcAdBudgetValue
            : !isHavingPpc && isHavingDsp
            ? dspAdBudgetValue
            : 0;

        // below code to update tacos milestones list
        const tacosMilestoneList = [...tacosMilestonesToBeUpdated?.milestone];
        // get previous tacos value
        let tacosValue = tacosMilestoneList?.[index]?.value;

        // calculate the tacos value
        if (overallSalesValue > 0) {
          tacosValue = (totalAdValue / overallSalesValue) * 100;
        } else {
          tacosValue = 0;
        }

        // update the auto calculated tacos values to current milestone month index
        tacosMilestoneList[index] = {
          ...tacosMilestonesToBeUpdated?.milestone[index],
          value:
            tacosValue > 0 ? Number(tacosValue?.toFixed(2))?.toString() : '',
        };

        // update the new tacos milestone array in the tacos milestones object
        tacosMilestonesToBeUpdated = {
          ...tacosMilestonesToBeUpdated,
          milestone: [...tacosMilestoneList],
        };

        // update the tacos milestones in the parent milestone
        parentMilestone[tacosMilestonesIndex] = tacosMilestonesToBeUpdated;
      }

      let updatedGoalObject = {};

      if (goalFromEditedGoalList?.length) {
        updatedGoalObject = {
          ...goalFromEditedGoalList[0],
          milestones: parentMilestone,
        };
      }

      if (!goalFromEditedGoalList?.length) {
        const listtt = [...editedGoalsData];
        listtt.push(updatedGoalObject);
        setEditedGoalsData([...listtt]);
      } else {
        let editedGoalIndex = null;

        editedGoalsData?.forEach((editedListGoal, editedgoalIndex) => {
          if (editedListGoal?.marketplace === selectedMarketplace?.id) {
            editedGoalIndex = editedgoalIndex;
          }
          return null;
        });

        const listtt = [...editedGoalsData];
        listtt[editedGoalIndex] = { ...updatedGoalObject };
        setEditedGoalsData([...listtt]);
      }
    } else {
      const list = [...newGoalsData?.milestone];
      list[index] = {
        value: editedValue,
        month: monthData?.month,
        year: monthData?.year,
      };
      setNewGoalsData({ ...newGoalsData, milestone: [...list] });

      const goalFromEditedGoalList = editedGoalsData?.filter(
        (goalsData) => goalsData?.marketplace === selectedMarketplace?.id,
      );
      const updatedMilestoneList = [...goalFromEditedGoalList[0]?.milestones];
      const newlAddedMilestone = updatedMilestoneList?.filter(
        (milestone) => milestone?.type === 'optional' && !milestone?.id,
      );
      let milestoneIndex = updatedMilestoneList?.length;

      if (newlAddedMilestone?.length) {
        updatedMilestoneList?.forEach((milestone, editedmilestoneIndex) => {
          if (milestone?.type === 'optional' && !milestone?.id) {
            milestoneIndex = editedmilestoneIndex;
          }
          return null;
        });
      }
      updatedMilestoneList[milestoneIndex] = {
        ...newGoalsData,
        milestone: [...list],
      };

      let editedGoalIndex = null;

      editedGoalsData?.forEach((editedListGoal, editedgoalIndex) => {
        if (editedListGoal?.marketplace === selectedMarketplace?.id) {
          editedGoalIndex = editedgoalIndex;
        }
        return null;
      });

      const listtt = [...editedGoalsData];
      listtt[editedGoalIndex] = {
        ...listtt[editedGoalIndex],
        milestones: [...updatedMilestoneList],
      };
      setEditedGoalsData([...listtt]);
    }
  };

  // auto scroll to specific table cell
  const autoScrollToElement = useCallback((elId) => {
    const element = document.getElementById(elId);
    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, []);

  // useEffect
  useEffect(() => {
    autoScrollToElement('scrollToElement');
  }, [autoScrollToElement]);

  const changeCollapseFlag = (marketplace) => {
    const changedCollapseItem = marketplaceCollapse?.find(
      (collapseItem) => collapseItem?.marketplace === marketplace?.id,
    );

    const updatedList = marketplaceCollapse?.map((item) => {
      if (item?.marketplace === marketplace?.id) {
        return { ...item, collapse: !changedCollapseItem?.collapse };
      }
      return item;
    });
    setMarketplaceCollapse(updatedList);
  };

  // display react-tooltip icon
  const displayTooltipIcon = (tooltipId, tooltipData) => {
    return (
      <img
        className="ml-1 cursor"
        style={{
          verticalAlign: 'text-bottom',
          fontWeight: '300',
        }}
        width="14px"
        src={InfoIcons}
        alt="info"
        data-tip={tooltipData}
        data-for={tooltipId}
      />
    );
  };

  // display react-tooltip data
  const displayTooltipData = (tooltipId) => {
    return (
      <ReactTooltip
        className="text-transformation-none"
        id={tooltipId}
        aria-haspopup="true"
        place="top"
        effect="solid"
        html
      />
    );
  };

  // Number input validation for goal value = 0 not allowed
  const checkGoalInputValiadation = (inputValue) => {
    const { value } = inputValue;

    if (Number(value) > 0 || value?.length === 0) {
      return true;
    }
    return false;
  };

  // display numer input box for goal
  const showNumberInputbox = (
    monthData,
    index,
    selectedMarketplace,
    milestones,
  ) => {
    const dataType = milestones?.data_type;
    const goalName = milestones?.goal_name;
    const isZeroNotAllowed =
      goalName?.includes('Overall Sales Goals') ||
      goalName?.includes('PPC Ad Budget');
    return (
      <BorderLessField>
        {goalName === 'Advertising Goals' ||
        milestones?.data_type === 'text' ? (
          <input
            id={selectedMarketplace?.id}
            className="form-control modal-input-control grey-bg "
            type="text"
            placeholder="Enter Goal"
            name="goal"
            defaultValue={monthData?.value}
            onChange={(event) =>
              handleInputChange(
                event,
                monthData,
                index,
                selectedMarketplace,
                milestones,
                false,
              )
            }
          />
        ) : isZeroNotAllowed ? (
          <NumberFormat
            id={`${goalName}_${monthData?.month}/${monthData?.year}`}
            name={`${goalName}_${monthData?.month}/${monthData?.year}`}
            className="form-control modal-input-control grey-bg"
            placeholder="Enter Value"
            onChange={(event) => {
              handleInputChange(
                event,
                monthData,
                index,
                selectedMarketplace,
                milestones,
              );
            }}
            defaultValue={monthData?.value}
            thousandSeparator
            prefix={dataType === 'dollar' ? '$' : ''}
            suffix={dataType === 'percentage' ? '%' : ''}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
            isAllowed={checkGoalInputValiadation}
          />
        ) : (
          <NumberFormat
            id={`${goalName}_${monthData?.month}/${monthData?.year}`}
            name={`${goalName}_${monthData?.month}/${monthData?.year}`}
            className="form-control modal-input-control grey-bg"
            placeholder="Enter Value"
            onChange={(event) => {
              handleInputChange(
                event,
                monthData,
                index,
                selectedMarketplace,
                milestones,
              );
            }}
            defaultValue={monthData?.value}
            thousandSeparator
            prefix={dataType === 'dollar' ? '$' : ''}
            suffix={dataType === 'percentage' ? '%' : ''}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
          />
        )}
      </BorderLessField>
    );
  };

  // display numer input box for newly added goal
  const showNumberInputboxForNewGoals = (
    monthData,
    monthIndex,
    selectedMarketplace,
    milestones,
    goal,
    item,
    newlyAddedGoalIndex,
  ) => {
    const dataType = item?.data_type;
    return (
      <BorderLessField>
        {dataType === 'text' ? (
          <input
            id={marketplaceForNewGoal?.id}
            className="form-control modal-input-control grey-bg"
            type="text"
            placeholder="Enter Goal"
            name="goal"
            defaultValue={monthData?.value}
            onChange={(event) => {
              handleNewGoalInput(
                event,
                monthData,
                monthIndex,
                selectedMarketplace,
                milestones,
                goal,
                item,
                newlyAddedGoalIndex,
              );
            }}
          />
        ) : (
          <NumberFormat
            name={`${monthData?.month}/${monthData?.year} `}
            className="form-control modal-input-control grey-bg"
            placeholder="Enter Value"
            onChange={(event) => {
              handleNewGoalInput(
                event,
                monthData,
                monthIndex,
                selectedMarketplace,
                milestones,
                goal,
                item,
                newlyAddedGoalIndex,
              );
            }}
            defaultValue={monthData?.value}
            thousandSeparator
            prefix={dataType === 'dollar' ? '$' : ''}
            suffix={dataType === 'percentage' ? '%' : ''}
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
          />
        )}
      </BorderLessField>
    );
  };

  const handleGoalNameChange = (event, selectedMarketplace, index) => {
    // fetch goals data of selectedmarketplace from newGoalsData list
    const data = newGoalsData?.filter((newGoal) =>
      Object.keys(newGoal)?.includes(selectedMarketplace?.id),
    );

    // if already present data in newGoalsData list of selected marketplace
    if (data?.length) {
      // get array of newly added goals in list variable
      const list = [...data?.[0]?.[selectedMarketplace?.id]];
      // push new goal object in list of goals of selected marketplace
      list[index] = { ...list[index], goal_name: event?.target?.value };

      // extract list of other marketplace and its newly added goal except selected marketpalce
      const goalsExceptUpdatedGoal = newGoalsData?.filter((newGoal) => {
        if (!Object.keys(newGoal)?.includes(selectedMarketplace?.id)) {
          return newGoal;
        }
      });

      // create new object with updated list of newly added goal to update in original list 'newGoalsData'
      const updatedGoal = { [selectedMarketplace?.id]: list };
      // push updated object in list of goals which not contain selected marketplace data. so final list is created
      goalsExceptUpdatedGoal?.push(updatedGoal);

      // update original list
      setNewGoalsData(goalsExceptUpdatedGoal);
    }
  };

  const displayNumber = (num) => {
    const goalValue = Number(num);
    const res = goalValue
      ?.toFixed(2)
      .toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return res;
  };

  // display goal value and input box
  const displayGoalValueAndInputbox = (
    monthYearValue,
    index,
    selectedMarketplace,
    milestones,
    goal,
  ) => {
    const dataType = milestones?.data_type;
    const isTextData =
      milestones?.goal_name === 'Advertising Goals' ||
      milestones?.data_type === 'text';
    const isTacos = milestones?.goal_name === 'TACOS';
    return isEditGoals && !isTacos ? (
      showNumberInputbox(
        monthYearValue,
        index,
        selectedMarketplace,
        milestones,
        goal,
        'default',
      )
    ) : monthYearValue?.value ? (
      <>
        {isTextData ? (
          <>
            <div width={cellTHWidth} className="text-medium-font">
              <span data-tip={monthYearValue?.value} data-for="adGoals">
                {monthYearValue?.value?.length > 11
                  ? monthYearValue?.value?.slice(0, 11)
                  : monthYearValue?.value}
                {monthYearValue?.value?.length > 11 ? (
                  <span style={{ color: 'black' }}>...</span>
                ) : (
                  ''
                )}
              </span>
              <ReactTooltip id="adGoals" aria-haspopup="true" place="top" />
            </div>
          </>
        ) : (
          <div className={isTacos && isEditGoals ? 'goals-gray-box one' : ''}>
            {dataType === 'dollar' ? '$' : ''}
            {displayNumber(monthYearValue?.value)}
            {dataType === 'percentage' ? '%' : ''}
          </div>
        )}
      </>
    ) : isEditGoals && milestones?.goal_name === 'TACOS' ? (
      <div className="goals-gray-box two">
        {monthYearValue?.value ? (
          <>
            {dataType === 'dollar' ? '$' : ''}
            {monthYearValue.value}
            {dataType === 'percentage' ? '%' : ''}
          </>
        ) : null}
      </div>
    ) : null;
  };

  // mapping the goals value in respective months column cell
  const showGoalsValuebyMonthNames = (
    milestones,
    selectedMarketplace,
    goal,
  ) => {
    return monthNames?.map((monthData, index) => {
      return milestones?.milestone
        ?.filter(
          (milestone) =>
            milestone?.year === monthData?.year &&
            milestone?.month === monthData?.month,
        )
        ?.map((item) => {
          return (
            <td width={cellWidth} key={`${item?.year}${item?.month}`}>
              {displayGoalValueAndInputbox(
                item,
                index,
                selectedMarketplace,
                milestones,
                goal,
              )}
            </td>
          );
        });
    });
  };

  // create milestone list with empty value and respective year and month
  const setDefaultMilestoneToNewGoal = () => {
    const list = [];
    if (editedGoalsData?.length) {
      editedGoalsData?.[0]?.milestones?.[0]?.milestone?.forEach(
        (originalGoalMilestone) => {
          list.push({
            year: originalGoalMilestone?.year,
            month: originalGoalMilestone?.month,
            value: '',
          });
        },
      );
      return list;
    }
    return [];
  };

  const onClickAddNewGoal = (selectedMarketplace, goal) => {
    // fetch goals data of selectedmarketplace from newGoalsData list
    const data = newGoalsData?.filter((newGoal) =>
      Object.keys(newGoal)?.includes(selectedMarketplace?.id),
    );

    const lastSequenceOfGoal =
      goal?.milestones?.[goal?.milestones?.length - 1]?.sequence;
    const lengthOfNewGoalOFMarketplace = data?.[0]?.[selectedMarketplace?.id]
      ?.length
      ? data?.[0]?.[selectedMarketplace?.id]?.length
      : 0;

    // created default data to patch
    const patchData = {
      goal: goal?.id,
      sequence: lastSequenceOfGoal + lengthOfNewGoalOFMarketplace + 1,
      milestone: setDefaultMilestoneToNewGoal(goal),
      goal_name: '',
      type: 'optional',
      data_type: 'text',
    };
    // if already present data in newGoalsData list of selected marketplace
    if (data?.length) {
      // get array of newly added goals in list variable
      const list = [...data?.[0]?.[selectedMarketplace?.id]];
      // push new goal object in list of goals of selected marketplace
      list.push(patchData);

      // extract list of other marketplace and its newly added goal except selected marketpalce
      const goalsExceptUpdatedGoal = newGoalsData?.filter((newGoal) => {
        if (!Object.keys(newGoal)?.includes(selectedMarketplace?.id)) {
          return newGoal;
        }
      });

      // create new object with updated list of newly added goal to update in original list 'newGoalsData'
      const updatedGoal = { [selectedMarketplace?.id]: list };
      // push updated object in list of goals which not contain selected marketplace data. so final list is created
      goalsExceptUpdatedGoal?.push(updatedGoal);

      // update original list
      setNewGoalsData(goalsExceptUpdatedGoal);
    } // if not present data in newGoalsData list of selected marketplace then simply add dictionary
    else {
      setNewGoalsData([
        ...newGoalsData,
        { [selectedMarketplace?.id]: [patchData] },
      ]);
    }
  };

  // handle new goals data type change events
  const handleNewGoalsDataTypeChangeEvents = (
    index,
    item,
    option,
    selectedMarketplace,
  ) => {
    const marketplaceID = selectedMarketplace?.id;
    if (item?.type === 'default') {
      // takeout goal from editedlist which is changed
      const goalFromEditedGoalList = editedGoalsData?.filter(
        (goalsData) => goalsData?.marketplace === selectedMarketplace?.id,
      );

      let milestonesToBeUpdated = goalFromEditedGoalList[0]?.milestones[index];

      milestonesToBeUpdated = {
        ...milestonesToBeUpdated,
        data_type: option?.type,
      };

      let parentMilestone = [];
      if (goalFromEditedGoalList?.length) {
        parentMilestone = [...goalFromEditedGoalList[0]?.milestones];
        parentMilestone[index] = milestonesToBeUpdated;
      }

      let updatedGoalObject = {};

      if (goalFromEditedGoalList?.length) {
        updatedGoalObject = {
          ...goalFromEditedGoalList[0],
          milestones: parentMilestone,
        };
      }

      if (!goalFromEditedGoalList?.length) {
        const listtt = [...editedGoalsData];
        listtt.push(updatedGoalObject);
        setEditedGoalsData([...listtt]);
      } else {
        let editedGoalIndex = null;

        editedGoalsData?.forEach((editedListGoal, editedgoalIndex) => {
          if (editedListGoal?.marketplace === selectedMarketplace?.id) {
            editedGoalIndex = editedgoalIndex;
          }
          return null;
        });

        const listtt = [...editedGoalsData];
        listtt[editedGoalIndex] = { ...updatedGoalObject };

        setEditedGoalsData([...listtt]);
      }
    } else {
      const list = newGoalsData?.[0]?.[marketplaceID];
      list[index] = { ...list[index], data_type: option?.type };

      setNewGoalsData([{ [marketplaceID]: list }]);
    }
  };

  // render new goals data type html
  const renderNewGoalsDataTypeHTML = (
    newlyAddedGoalsIndex,
    item,
    dataType,
    selectedMarketplace,
  ) => {
    return (
      <div className="goals-radio-button-list" key={selectedMarketplace?.id}>
        <ul>
          {goalsDataType?.map((option) => {
            const inputId = `${goalsType}${option?.label}${selectedMarketplace?.id}${newlyAddedGoalsIndex}`;
            const inputName = `${goalsType}${selectedMarketplace?.id}${item?.goal_name}${newlyAddedGoalsIndex}`;
            return (
              <li key={`${option?.label}${selectedMarketplace?.id}`}>
                <ModalRadioCheck>
                  <label
                    className="checkboxes radio-container customer-list"
                    htmlFor={inputId}
                  >
                    {option?.label}
                    <input
                      type="radio"
                      name={inputName}
                      id={inputId}
                      value={option?.label}
                      onChange={(event) =>
                        handleNewGoalsDataTypeChangeEvents(
                          newlyAddedGoalsIndex,
                          item,
                          option,
                          selectedMarketplace,
                          event,
                        )
                      }
                      checked={dataType === option?.type}
                    />
                    <span className="checkmark checkmark-customer-list" />
                  </label>
                </ModalRadioCheck>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  // Add new goal html
  const showAddNewGoalRow = (selectedMarketplace, goal, item, index) => {
    const dataType = item?.data_type;
    return (
      <>
        {isTablet ? (
          <>
            <td width={cellTHWidth} className="sticky-column">
              <BorderLessField>
                <input
                  id={marketplaceForNewGoal?.id}
                  className="form-control modal-input-control grey-bg"
                  type="text"
                  placeholder="Enter Goal Name"
                  name="goal_name"
                  defaultValue={newGoalsData?.[index]?.goal_name}
                  onChange={(event) =>
                    handleGoalNameChange(event, selectedMarketplace, index)
                  }
                />
              </BorderLessField>
              {renderNewGoalsDataTypeHTML(
                index,
                item,
                dataType,
                selectedMarketplace,
              )}
            </td>
            {monthNames?.map((monthData, monthIndex) => {
              return (
                <td width={cellWidth} key={monthData?.label}>
                  {showNumberInputboxForNewGoals(
                    monthData,
                    monthIndex,
                    selectedMarketplace,
                    newGoalsData,
                    goal,
                    item,
                    index,
                  )}
                </td>
              );
            })}
          </>
        ) : (
          <fieldset className="shape-without-border w-430 mt-3 mb-4">
            <div className="row">
              <div className="col-12">
                <BorderLessField>
                  <input
                    id={marketplaceForNewGoal?.id}
                    className="form-control modal-input-control grey-bg mb-3"
                    type="text"
                    placeholder="Enter Goal Name"
                    name="goal_name"
                    defaultValue={newGoalsData?.[index]?.goal_name}
                    onChange={(event) =>
                      handleGoalNameChange(event, selectedMarketplace, index)
                    }
                  />
                </BorderLessField>
              </div>
              {monthNames?.map((monthData, monthIndex) => {
                return (
                  <div className="col-6 mb-3" width={cellWidth}>
                    <div className="label" id={monthData?.key}>
                      {dayjs(monthData?.label).format('MMMM')}
                    </div>
                    {showNumberInputboxForNewGoals(
                      monthData,
                      monthIndex,
                      selectedMarketplace,
                      newGoalsData,
                      goal,
                      item,
                      index,
                    )}
                  </div>
                );
              })}
              <div className="col-12">
                {renderNewGoalsDataTypeHTML(
                  index,
                  item,
                  dataType,
                  selectedMarketplace,
                )}
              </div>
            </div>
          </fieldset>
        )}
      </>
    );
  };

  // function for saving the newly added goals data
  const saveNewGoal = () => {
    const newGoalsMarketplaceKeys = newGoalsData?.map(
      (newGoal) => Object.keys(newGoal)?.[0],
    );

    const finalDataOfNewlyaddedGoal = [];
    newGoalsMarketplaceKeys?.forEach((marketplace) => {
      let goalsWithGoalName = [];
      newGoalsData?.forEach((goal) => {
        if (goal?.[marketplace]?.length) {
          goalsWithGoalName = goal?.[marketplace].filter(
            (newItem) => newItem?.goal_name,
          );
        }
      });

      finalDataOfNewlyaddedGoal.push({ [marketplace]: goalsWithGoalName });
    });

    const updatedData = [...editedGoalsData];
    let newlyAddedGoalsMilestones = [];

    newGoalsMarketplaceKeys?.forEach((marketplace) => {
      editedGoalsData?.forEach((originalGoal, index) => {
        if (originalGoal?.marketplace === marketplace) {
          const originalGoalsMilestones = [...originalGoal?.milestones];
          const goalFromNewList = finalDataOfNewlyaddedGoal?.filter((newGoal) =>
            Object.keys(newGoal)?.includes(marketplace),
          )?.[0];

          newlyAddedGoalsMilestones = goalFromNewList?.[marketplace]
            ? goalFromNewList?.[marketplace]
            : [];
          const finalListOfMilestones = [
            ...originalGoalsMilestones,
            ...newlyAddedGoalsMilestones,
          ];

          originalGoal = {
            ...originalGoal,
            milestones: [...finalListOfMilestones],
          };

          updatedData[index] = originalGoal;
        }
      });
    });

    if (newlyAddedGoalsMilestones?.length) {
      updateGoals(updatedData, goalsType);
    } else {
      setNewGoalsData([]);
      setIsNewGoalAdded(false);
    }
  };

  // cancel events of newly added goals
  const onCancelOfAddNewGoal = () => {
    setNewGoalsData([]);
    setIsNewGoalAdded(false);
  };

  const enableForClickedMarketplace = (selectedMarketplace) => {
    const newGoalsMarketplaceKey = newGoalsData?.map(
      (newGoal) => Object.keys(newGoal)?.[0],
    )?.[0];
    if (newGoalsMarketplaceKey === selectedMarketplace?.id) return false;
    return true;
  };

  const displayAddNewGoalBtn = (selectedMarketplace, goal) => {
    return (
      <tr
        className={
          newGoalsData?.length &&
          enableForClickedMarketplace(selectedMarketplace)
            ? 'disabled'
            : ''
        }
      >
        <td width={cellTHWidth} className="sticky-column ">
          <Button
            className={
              newGoalsData?.length &&
              enableForClickedMarketplace(selectedMarketplace)
                ? 'btn-add-contact extraNormal-text disabled'
                : 'btn-add-contact extraNormal-text '
            }
            onClick={() => {
              setMarketplaceforNewGoal(selectedMarketplace);
              onClickAddNewGoal(selectedMarketplace, goal);
              setIsNewGoalAdded(true);
            }}
            style={{ textTransform: 'uppercase', minHeight: '0' }}
          >
            <FontAwesomeIcon
              color="#FF5933"
              fontSize="12px"
              icon="fa-regular fa-plus"
            />{' '}
            Add Goal
          </Button>
        </td>
      </tr>
    );
  };

  const displaySaveAndCancelBtn = (selectedMarketplace) => {
    return (
      <>
        {isTablet ? (
          <tr>
            <td
              className="sticky-column text-left pr-4"
              style={{ borderBottom: 'none' }}
              colSpan="2"
              width={cellTHWidth}
            >
              <div>
                <Button
                  type="button"
                  className="btn-primary on-boarding w-10"
                  onClick={() => saveNewGoal(selectedMarketplace)}
                >
                  Save
                </Button>
                <Button
                  className=" ml-3 btn-transparent w-10"
                  type="button"
                  onClick={() => {
                    setMarketplaceforNewGoal({});
                    onCancelOfAddNewGoal(selectedMarketplace);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </td>
          </tr>
        ) : (
          <div className="mt-3">
            <Button
              type="button"
              className="btn-primary on-boarding w-10"
              onClick={() => saveNewGoal(selectedMarketplace)}
            >
              Save
            </Button>
            <Button
              className=" ml-3 btn-transparent w-10"
              type="button"
              onClick={() => {
                setMarketplaceforNewGoal({});
                onCancelOfAddNewGoal(selectedMarketplace);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </>
    );
  };

  // display marketplace collapse Icon
  const displayMarketplaceCollapseIcon = (marketplaceId, top, right, left) => {
    return (
      <FontAwesomeIcon
        icon={`${
          marketplaceCollapse?.find(
            (item) => item?.marketplace === marketplaceId,
          )?.collapse
            ? 'fa-regular fa-minus'
            : 'fa-regular fa-plus'
        }`}
        style={{
          color: Theme.orange,
          fontSize: Theme.small,
          position: 'absolute',
          top,
          right,
          left,
        }}
      />
    );
  };

  // display badge for newly added goal
  const displayNewLable = (milestone) => {
    return milestone?.type !== 'default' ? (
      <>
        <span className="added new-item ml-2">New</span>
        {customerData?.goal_set?.show ? (
          <>
            <FontAwesomeIcon
              onClick={() => {
                deleteAddedGoal(milestone);
              }}
              icon="fa-regular fa-trash"
              style={{
                color: '#d63649',
                fontSize: '14px',
              }}
              className="cursor  pl-1"
              data-tip="Delete"
              data-for="delete"
            />
            <ReactTooltip id="delete" aria-haspopup="true" place="top" />
          </>
        ) : null}
      </>
    ) : null;
  };

  // display tolltip for milestone label
  const displayMilestoneLabelTooltip = (milestone) => {
    return (
      <>
        {milestone?.goal_name === 'DSP Ad Budget' ? (
          <>
            {displayTooltipIcon(
              'dspGoals',
              "If BP has no DSP service, input '0' as value.",
            )}
            {displayTooltipData('dspGoals')}
          </>
        ) : null}
        {milestone?.goal_name === 'PPC Ad Budget' ||
        milestone?.goal_name === 'Overall Sales Goals' ? (
          <>
            {displayTooltipIcon(
              'ppcGoals',
              "Mandatory - '0' value is not allowed.",
            )}
            {displayTooltipData('ppcGoals')}
          </>
        ) : null}
        {milestone?.goal_name === 'TACOS' ? (
          <>
            {displayTooltipIcon('tacos', 'TACOS is auto-calculated.')}
            {displayTooltipData('tacos')}
          </>
        ) : null}
      </>
    );
  };

  // display goal milestone label
  const displayGoalMilestoneLabel = (milestone) => {
    const goalLabel = milestone?.goal_name || '';
    return (
      <div className="normal-text-black text-medium">
        <span data-tip={goalLabel} data-for="info">
          {milestone?.type === 'optional'
            ? goalLabel?.slice(0, 11)
            : goalLabel === 'Traffic' && goalsType !== 'seller'
            ? 'Glance Views'
            : goalLabel}
          {milestone?.type === 'optional' && goalLabel?.length > 11 ? (
            <span style={{ color: 'black' }}>...</span>
          ) : (
            ''
          )}
        </span>
        <ReactTooltip id="info" aria-haspopup="true" place="top" />
        {displayNewLable(milestone)}
        {displayMilestoneLabelTooltip(milestone)}
      </div>
    );
  };

  // display goal marketplace collapse header row
  const displayMarketplaceCollpaseHead = (marketplace) => {
    return (
      <tr
        className="collapse-body-row collapse-btn cursor "
        role="presentation"
        type="button"
        onClick={() => {
          changeCollapseFlag(marketplace);
        }}
      >
        <td
          key={`${marketplace?.label}-${marketplace?.id}`}
          className="sticky-column collapse-body py-2 marketplace-row"
        >
          <span
            style={{ paddingRight: '35px' }}
            role="presentation"
            onClick={() => {
              changeCollapseFlag(marketplace);
            }}
          >
            {displayMarketplaceCollapseIcon(
              marketplace?.id,
              '15px',
              null,
              '20px',
            )}
          </span>
          {marketplace?.label}
        </td>
        <td
          key={`${marketplace?.label}-${marketplace?.id}-collapse`}
          className="collapse-body marketplace-row"
          colSpan="100%"
        />
      </tr>
    );
  };

  // display goal marketplace collapse Body row
  const displayMarketplaceCollpaseBody = (marketplace, goal) => {
    return (
      <tr>
        <td colSpan="13" style={{ padding: '0' }}>
          <Collapse
            isOpened={
              marketplaceCollapse?.find(
                (item) => item?.marketplace === marketplace?.id,
              )?.collapse
            }
          >
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody className="collapse" id="collapseExample">
                {goal?.milestones?.map((milestone, milestoneIndex) => {
                  return (
                    <tr
                      key={milestone?.id}
                      className={
                        (isEditGoals && newGoalsData?.length) || isNewGoalAdded
                          ? 'disabled'
                          : ''
                      }
                    >
                      <td
                        width={cellTHWidth}
                        className="sticky-column text-medium-font"
                      >
                        {displayGoalMilestoneLabel(milestone)}
                        {isEditGoals && milestone?.goal_name === 'Rev Share'
                          ? renderNewGoalsDataTypeHTML(
                              milestoneIndex,
                              milestone,
                              milestone?.data_type,
                              marketplace,
                            )
                          : null}
                      </td>

                      {showGoalsValuebyMonthNames(milestone, marketplace, goal)}
                    </tr>
                  );
                })}
                {isEditGoals
                  ? newGoalsData
                      ?.filter((newGoal) =>
                        Object.keys(newGoal)?.includes(marketplace?.id),
                      )?.[0]
                      ?.[marketplace?.id]?.map((item, index) => (
                        <tr className="text-medium-font">
                          {showAddNewGoalRow(marketplace, goal, item, index)}
                        </tr>
                      ))
                  : null}
                {!customerData?.goal_set?.show || !isEditGoals || isNewGoalAdded
                  ? null
                  : displayAddNewGoalBtn(marketplace, goal)}
                {marketplaceForNewGoal?.id === marketplace?.id &&
                newGoalsData?.length
                  ? displaySaveAndCancelBtn(marketplace)
                  : null}
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    );
  };

  // dispaly goals table data
  const displayGoalsTable = () => {
    return selectedMarketplaces?.map((marketplace) => {
      return editedGoalsData
        ?.filter(
          (goal) =>
            goal?.marketplace === marketplace?.id &&
            goal?.contract_account_type === marketplace?.accountType,
        )
        .map((goal) => {
          return (
            <tbody
              key={`${goalsType}-${marketplace?.id}-${marketplace?.label}`}
            >
              {displayMarketplaceCollpaseHead(marketplace)}
              {displayMarketplaceCollpaseBody(marketplace, goal)}
            </tbody>
          );
        });
    });
  };

  // display gooals desktop view
  const displayGoalsTabletAndDesktopView = () => {
    return (
      <TableWrapper>
        <div className="modal-body">
          <div>
            <DesktopTable style={{ tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th
                    key="goals"
                    width={cellTHWidth}
                    className="sticky-column header"
                  >
                    FORECAST
                  </th>
                  {monthNames?.map((item) => {
                    return (
                      <th id={item?.key} key={item?.id} width={cellWidth}>
                        {dayjs(item?.label).format('MMM YYYY')}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {displayGoalsTable()}
            </DesktopTable>
          </div>
        </div>
      </TableWrapper>
    );
  };

  // display goals mobile view
  const displayGoalsMobileView = () => {
    return (
      <GoalMobileView
        selectedMarketplaces={selectedMarketplaces}
        editedGoalsData={editedGoalsData}
        displayNewLable={displayNewLable}
        changeCollapseFlag={changeCollapseFlag}
        marketplaceCollapse={marketplaceCollapse}
        monthNames={monthNames}
        enableForClickedMarketplace={enableForClickedMarketplace}
        customerData={customerData}
        isEditGoals={isEditGoals}
        displayAddNewGoalBtn={displayAddNewGoalBtn}
        showAddNewGoalRow={showAddNewGoalRow}
        newGoalsData={newGoalsData}
        marketplaceForNewGoal={marketplaceForNewGoal}
        displaySaveAndCancelBtn={displaySaveAndCancelBtn}
        displayGoalMilestoneLabel={displayGoalMilestoneLabel}
        displayGoalValueAndInputbox={displayGoalValueAndInputbox}
        displayMarketplaceCollapseIcon={displayMarketplaceCollapseIcon}
      />
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <>
      {selectedMarketplaces?.length && editedGoalsData?.length ? (
        <GoalsStyle>
          {isTablet
            ? displayGoalsTabletAndDesktopView()
            : displayGoalsMobileView()}
        </GoalsStyle>
      ) : (
        <p className=" gray-medium-text my-2 text-center">No Goals created</p>
      )}
    </>
  );
}

export default Goals;
Goals.defaultProps = {
  isEditGoals: false,
  isNewGoalAdded: false,

  goalsType: '',

  marketplaceForNewGoal: {},

  monthNames: [],
  marketplaceCollapse: [],
  newGoalsData: [],
  editedGoalsData: [],
  selectedMarketplaces: [],

  updateGoals: () => {},
  deleteAddedGoal: () => {},
  setNewGoalsData: () => {},
  setIsNewGoalAdded: () => {},
  setEditedGoalsData: () => {},
  setMarketplaceCollapse: () => {},
  setMarketplaceforNewGoal: () => {},
};

Goals.propTypes = {
  goalsType: string,

  isEditGoals: bool,
  isNewGoalAdded: bool,

  marketplaceForNewGoal: shape({}),
  monthNames: arrayOf(shape({})),
  selectedMarketplaces: arrayOf(shape({})),
  marketplaceCollapse: arrayOf(shape({})),
  newGoalsData: arrayOf(shape({})),
  editedGoalsData: arrayOf(shape({})),

  updateGoals: func,
  deleteAddedGoal: func,
  setNewGoalsData: func,
  setIsNewGoalAdded: func,
  setEditedGoalsData: func,
  setMarketplaceCollapse: func,
  setMarketplaceforNewGoal: func,
};
