// react imports
import React from 'react';

// third party imports
import { components } from 'react-select';
import { shape, string } from 'prop-types';

const DropdownOptions = ({ ...props }) => {
  const { Option } = components;

  const { data } = props;

  /* ********** Main return statement of this component ********** */
  return (
    <Option {...props}>
      <div className="dropdown-option">
        <span style={{ fontSize: '14px' }}>{data?.label}</span>
        <div style={{ fontSize: '10px' }}>{data?.sub}</div>
      </div>
    </Option>
  );
};

export default DropdownOptions;

DropdownOptions.defaultProps = {
  data: {},
};

DropdownOptions.propTypes = {
  data: shape({ label: string, sub: string }),
};
