import React, { useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { shape, string } from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../theme/Theme';
import CosContainer from './COS/COSContainer';
import GoalsAndStrategyContainer from './Goals/GoalsAndStrategyContainer';
import { PageLoader, Tabs } from '../../../../common';
import { PATH_CUSTOMER_DETAILS, goalsTabConstant } from '../../../../constants';

function GoalsAndCosContainer({
  isBBEInternalUser,
  accountType,
  goalsAccountTypeFlag,
}) {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  // https://bbe.atlassian.net/browse/PDV-9253 - Hide COS TAB - Single BP View and BP View
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isSuperAdmin = userInfo?.role?.includes('Super Admin');

  const { id } = useParams();
  const [viewComponent, setViewComponent] = useState('goals');
  const [clickOnRefreshBtnFlag, setClickOnRefreshBtn] = useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  // display goals tab list
  const displayGoalsTabList = () => {
    return (
      <Tabs>
        <ul className="tabs border-none">
          {goalsTabConstant?.map((item) => {
            return (
              <li
                key={item?.key}
                className={`${params?.nav === item?.key ? 'active' : ''} ${
                  isSuperAdmin ? '' : item?.class
                }`}
                onClick={() => {
                  setViewComponent(item?.key);
                  history.push(
                    `${PATH_CUSTOMER_DETAILS.replace(
                      ':id',
                      id,
                    )}?tab=goals&nav=${item?.key}&access=${isBBEInternalUser}`,
                  );
                }}
                role="presentation"
              >
                {item?.label}
              </li>
            );
          })}
        </ul>
      </Tabs>
    );
  };

  // display download report button
  const displayDownloadReportButton = () => {
    return (
      <div
        className={`black-heading-title text-medium-font mr-3 mt-1 cursor ${
          !isDataAvailable || isDataLoading ? 'disabled' : ''
        }`}
        role="presentation"
        onClick={() => {
          setDownloadReportLoader(true);
        }}
        style={{ maxWidth: '150px' }}
      >
        {downloadReportLoader ? (
          <PageLoader
            component="sidebar-loader"
            type="button"
            color={Theme?.orange}
          />
        ) : (
          <>
            <FontAwesomeIcon
              className="mr-2"
              color={Theme?.orange}
              icon="far fa-arrow-to-bottom"
            />
            Download Report
          </>
        )}
      </div>
    );
  };

  // display sync button
  const displaySyncButton = () => {
    return (
      <SyncButton
        onClick={() => {
          setClickOnRefreshBtn(true);
        }}
        className="cursor"
      >
        <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
      </SyncButton>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <div className="col-12 cutomer-middle-panel">
      <div className="row mb-1">
        <div className="col-12 col-md-8">{displayGoalsTabList()}</div>
        <div className="col-12 col-md-4 d-flex justify-content-end">
          {displayDownloadReportButton()}
          {displaySyncButton()}
        </div>
      </div>
      <div className="horizontal-line straight-line mt-n1" />

      {params?.nav === 'goals' ? (
        <GoalsAndStrategyContainer
          downloadReportLoader={downloadReportLoader}
          accountType={accountType}
          viewComponent={viewComponent}
          clickOnRefreshBtnFlag={clickOnRefreshBtnFlag}
          goalsAccountTypeFlag={goalsAccountTypeFlag}
          setClickOnRefreshBtn={setClickOnRefreshBtn}
          setIsDataLoading={setIsDataLoading}
          setIsDataAvailable={setIsDataAvailable}
          setDownloadReportLoader={setDownloadReportLoader}
        />
      ) : null}
      {params?.nav === 'cos' ? (
        <CosContainer
          clickOnRefreshBtnFlag={clickOnRefreshBtnFlag}
          setClickOnRefreshBtn={setClickOnRefreshBtn}
          viewComponent={viewComponent}
        />
      ) : null}
    </div>
  );
}

export default GoalsAndCosContainer;

GoalsAndCosContainer.defaultProps = {
  isBBEInternalUser: '',
  accountType: '',
  goalsAccountTypeFlag: {},
};

GoalsAndCosContainer.propTypes = {
  accountType: string,
  isBBEInternalUser: string,
  goalsAccountTypeFlag: shape({}),
};

const SyncButton = styled.div`
  height: 32px;
  width: 32px;
  border: 1px solid ${Theme.gray45};
  border-radius: 6px;
  color: ${Theme.gray90};
  text-align: center;
  padding: 7px;
  font-size: ${Theme.extraNormal};
`;
