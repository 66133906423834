/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { shape, string } from 'prop-types';

import Theme from '../../../../theme/Theme';
import { DesktopTable, Status } from '../../../../common';

export default function CustomerFeedbackDetails({
  selectedFeedbackForm,
  surveyType,
}) {
  const [surveyData, setSurveyData] = useState([]);

  useEffect(() => {
    if (surveyType === 'accountSetup') {
      setSurveyData([
        {
          question: '1. How easy was your account setup?',
          answer: selectedFeedbackForm.ratings,
        },
        {
          question: '2. What can we do to make it better?',
          answer: selectedFeedbackForm.feedback,
        },
      ]);
    } else {
      setSurveyData(selectedFeedbackForm?.response?.questions);
    }
  }, [selectedFeedbackForm, surveyType]);

  const generateHTML = () => {
    return (
      <>
        {surveyData?.map((item) => (
          <div className="m-4" key={item?.id}>
            <p
              className="black-heading-title"
              dangerouslySetInnerHTML={{
                __html: item?.heading || item?.question,
              }}
            />

            {item?.answer ? (
              <label htmlFor="question" className="capitalize">
                {item?.answer}
              </label>
            ) : (
              <>
                {item?.answers?.map((ans, index) => (
                  <>
                    <label
                      htmlFor="question"
                      className="capitalize"
                      key={ans?.row_id || ans?.other_id}
                    >
                      {item?.question_type === 'matrix' &&
                      ans?.choice_metadata?.weight
                        ? `${ans.choice_metadata.weight}-`
                        : ''}
                      {ans?.text}
                      {index < item?.answers?.length - 1 ? ', ' : ''}
                    </label>
                  </>
                ))}
              </>
            )}
            <div className="horizontal-line straight-line mt-3 d-lg-block " />
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      <DesktopTable className="mt-0">
        <thead>
          <tr>
            <th>Status</th>
            <th>Sent on</th>
            <th>Completed on</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-medium-font">
            <>
              <td>
                <Status
                  label={selectedFeedbackForm?.status}
                  backgroundColor={
                    selectedFeedbackForm?.status === 'answered'
                      ? Theme.lightGreen
                      : Theme.lightYellow
                  }
                  pointColor={
                    selectedFeedbackForm?.status === 'answered'
                      ? Theme.green
                      : Theme.yellow
                  }
                />
              </td>
              <td>{selectedFeedbackForm?.sent_on || 'N/A'}</td>
              <td>{selectedFeedbackForm?.completed_on || 'N/A'}</td>
            </>
          </tr>
        </tbody>
      </DesktopTable>
      <div className="scrollable-height">{generateHTML()}</div>
    </div>
  );
}

CustomerFeedbackDetails.propTypes = {
  selectedFeedbackForm: shape({}).isRequired,
  surveyType: string.isRequired,
};
