import React, { useState } from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';

import AwsQuicksightContainer from '../AWSQuicksight/AwsQuicksightContainer';
import AMCAudienceSummaryContainer from './AMCAudienceSummary/AMCAudienceSummaryContainer';
import { AMCDashboardNav } from '../../../../constants';
import { AMCStyle } from '../../../Customer/CustomerDetailView/CompanyPerformance/AmcInsights/AmcInsightStyle';
import {
  WhiteCard,
  SingleSelectDropdown,
  DropdownIndicator,
  ToggleSwitch,
} from '../../../../common';

function AMCDashboardRightPanel({
  selectedBp,
  selectedDSPManager,
  selectedMarketplace,
  selectedSecondLevelNav,
  setSelectedSecondLevelNav,
}) {
  const history = useHistory();

  const dashboardOptions = {
    sample: [
      {
        label: 'Standard Dashboard-Sample',
        value: 'Standard Dashboard-Sample',
      },
      {
        label: 'Advanced/Customized Dashboard-Sample',
        value: 'Customized Dashboard-Sample',
      },
    ],
  };

  const [selectedDashboardFilterOption, setSelectedDashboardFilterOption] =
    useState(dashboardOptions[selectedSecondLevelNav]?.[0]);

  const params = queryString.parse(history.location.search);

  const [showAMCAudienceLookalike, setShowAMCAudienceLookalike] = useState(
    params?.amcAudience === 'lookalike' || false,
  );

  // get dropdwon indicator for  dropdown filter
  const getSelectDropdownIndicator = () => {
    return {
      DropdownIndicator,
    };
  };

  const handleDashboardFilter = (event) => {
    setSelectedDashboardFilterOption(event);
  };

  const dashboardDropdown = (key) => {
    return (
      <SingleSelectDropdown
        id="view-data-for-dsp-audience-filter"
        placeholder={`Select `}
        className="single-select-dropdown "
        isApiCall={false}
        dropdownOptions={dashboardOptions[key]}
        selectedValue={selectedDashboardFilterOption}
        onChangeHandler={(event) => handleDashboardFilter(event)}
        dropdownComponents={getSelectDropdownIndicator}
        isSearchable={false}
      />
    );
  };

  const displayAmcDashboard = () => {
    if (selectedSecondLevelNav === 'sample') {
      if (
        selectedDashboardFilterOption?.value === 'Standard Dashboard-Sample'
      ) {
        return (
          <AwsQuicksightContainer
            id="StandardLeadershipDashboardSample"
            dashboardId="15a527bb-4cbc-427e-ba84-fae6464f79c3"
          />
        );
      }
      if (
        selectedDashboardFilterOption?.value === 'Customized Dashboard-Sample'
      ) {
        return (
          <AwsQuicksightContainer
            id="CustomizedLeadershipDashboardSample"
            dashboardId="cba056e7-abba-4b0a-9c68-721f1df3ebda"
          />
        );
      }
    }
    return null;
  };
  const setParams = (param, showLookalike) => {
    const paramsData = {};

    if (showLookalike) {
      paramsData.amcAudience = param;
    } else {
      delete paramsData?.amcAudience;
      delete params?.amcAudience;
    }

    const stringified =
      queryString &&
      queryString.stringify({
        ...params,
        ...paramsData,
      });

    history.push({
      pathname: `${history.location.pathname}`,
      search: `${stringified}`,
      state: `${history.location.state}`,
    });
  };

  // https://bbe.atlassian.net/browse/PDV-9060 - AMC Audience Lookalike improvements
  // display AMC Audience Lookalike toggle button
  const displayAMCAudienceLookalikeToggle = () => {
    return (
      <ToggleSwitch
        id="BT-AmcAudienceLookAlikeToggle"
        className="custom-switch cursor mb-3 mb-md-0"
        key="include-spreetail-brands-toggle"
        style={{ marginTop: '12px' }}
      >
        <label
          htmlFor="spreetailBrancds"
          className="label-name"
          key="include-spreetail-brands"
        >
          AMC Audience Lookalike
          <input
            type="checkbox"
            className="label-name"
            id="spreetailBrancds"
            onClick={() => {
              setShowAMCAudienceLookalike(!showAMCAudienceLookalike);
            }}
            onChange={(event) => {
              setParams('lookalike', event?.target?.checked);
            }}
            checked={showAMCAudienceLookalike}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  return (
    <AMCStyle>
      <WhiteCard className="mb-3 p-0">
        <div className="row">
          <div className="col-12 col-lg-9" style={{ overflowX: 'auto' }}>
            <ul
              className="second-level-navigation"
              style={{ minWidth: '700px' }}
            >
              {AMCDashboardNav?.map((item) => {
                return (
                  <li
                    key={item?.value}
                    role="presentation"
                    onClick={() => {
                      setSelectedSecondLevelNav(item?.value);
                      setSelectedDashboardFilterOption(
                        dashboardOptions[item?.value]?.[0],
                      );
                      history.push(
                        `${history?.location?.pathname}?subNav=${item?.value}`,
                      );
                    }}
                    className={
                      selectedSecondLevelNav === item?.value ? 'selected' : ''
                    }
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-lg-3 d-flex justify-content-end">
            <div className="mr-3">
              {selectedSecondLevelNav === 'sample'
                ? dashboardDropdown(selectedSecondLevelNav)
                : null}{' '}
              {selectedSecondLevelNav === 'amc-audience-summary'
                ? displayAMCAudienceLookalikeToggle()
                : null}
            </div>
          </div>
        </div>
      </WhiteCard>
      {selectedSecondLevelNav === 'amc-summary' ? (
        <AwsQuicksightContainer
          id="amcSummaryDashboard"
          dashboardId="8aa9bd39-d5f6-4244-ab4f-96c126cfd64d"
        />
      ) : (
        ''
      )}
      {selectedSecondLevelNav === 'spreetail-amc-summary-tab' ? (
        <AwsQuicksightContainer
          id="spreetailAMCSummaryDashboard"
          dashboardId="d40d0f6f-1847-4e24-8868-acb9d1abdbf0"
        />
      ) : (
        ''
      )}
      {selectedSecondLevelNav === 'amc-audience-summary' ? (
        <AMCAudienceSummaryContainer
          showAMCAudienceLookalike={showAMCAudienceLookalike}
          selectedBp={selectedBp}
          selectedDSPManager={selectedDSPManager}
          selectedMarketplace={selectedMarketplace}
        />
      ) : (
        ''
      )}
      {selectedSecondLevelNav === 'sample' ? displayAmcDashboard() : ''}
    </AMCStyle>
  );
}

export default AMCDashboardRightPanel;

AMCDashboardRightPanel.defaultProps = {
  selectedSecondLevelNav: '',
  selectedBp: [],
  selectedDSPManager: [],
  selectedMarketplace: {},
  setSelectedSecondLevelNav: () => {},
};

AMCDashboardRightPanel.propTypes = {
  selectedSecondLevelNav: string,
  selectedBp: arrayOf(shape()),
  selectedDSPManager: arrayOf(shape()),
  selectedMarketplace: shape({}),
  setSelectedSecondLevelNav: func,
};
